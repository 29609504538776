import React from "react"
import { css } from "@emotion/core"
import Container from './Container'

const CTA = () => (
    <div css={css`
            background: #334259;
            height:380px;
            margin-top:50px;
            margin-bottom:40px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'GTWalsheim';
            text-align:center;
        `}
    >
        <Container>
            <a href="/contact"
               css={css`
                background: none;
                border:3px solid white;
                font-family: 'GTWalsheim';
                padding:20px;
                font-size: 1.5rem;
                color: white;
                display:inline-block;
                max-width:55%;
            `}>
                Faîtes un pas vers la réussite de votre projet web dès maintenant.<span role="img" aria-label="Travaillons ensemble">🚀</span>
            </a>
        </Container>
    </div>
)

export default CTA
