import React from 'react'
import { css } from "@emotion/core"

const Grid = (props) => {
    return (
        <div css={css`
            display:grid;
            grid-template-columns: ${Array(Math.round(props.children.length / props.lines)).fill('auto').join(' ')};
            grid-gap: ${props.gap ? props.gap : '0'}rem;
            @media (max-width: 960px) {
                display:block;
            }
        `}
        >
            {props.children}
        </div>
    )
}

export default Grid