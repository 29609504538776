import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Banner from "../components/Banner"
import Container from "../components/Container"
import Grid from "../components/Grid"
import CTA from "../components/CTA"
import styled from "@emotion/styled"
import {css} from "@emotion/core"

const Title = styled.h1`
  font-size:3rem;
  margin-top:8vh;
  margin-bottom:3vh;
  letter-spacing: -0.08em;
  @media (max-width: 960px) {
    text-align:center;
  }
`
const Subtitle = styled.h2`
  font-family: 'FreightTextBook';
  font-size: 1.5rem;
  line-height: 2.5rem;
  margin-bottom:6vh;
  opacity: 0.8;
  font-weight: 600;
  max-width: 45rem;
  @media (max-width: 960px) {
    text-align:center;
  }
`

const Card = styled.div`
  @media (max-width: 960px) {
    padding: 0rem;
  }
`

const CardTitle = styled.h3`
  display:inline-block;
  font-family: 'GTWalsheim';
  font-size:1.5rem;
  font-weight:800;
  text-align: left;
  opacity:0.8;
  vertical-align:middle;
  @media (max-width: 960px) {
    margin-top:2rem;
  }
  line-height:2.25rem;
  border-bottom:0.5rem solid #A8DADC;
`
const CardSubtitle = styled.h4`
  display:block;
  font-family: 'GTWalsheim';
  font-weight:800;
  opacity: 0.5;
  font-size:1rem;
  @media (max-width: 960px) {
    margin-top:2rem;
  }
  line-height:2.25rem;
  text-align: left;
`

const StepTitle = styled.h3`
  display:inline-block;
  font-family: 'GTWalsheim';
  font-size:1.5rem;
  font-weight:800;
  text-align: left;
  opacity:0.8;
  vertical-align:middle;
  @media (max-width: 960px) {
    margin-top:2rem;
  }
  line-height:2.25rem;
`
const CardText = styled.p`
  font-size:1.5rem;
  opacity:0.8;
  font-family:'FreightTextBook';
  text-align: justify;
  text-justify: distribute;
  @media (max-width: 960px) {
    margin-top:1rem;
  }
`

const StepNumber = styled.span`
  display:inline-block;
  font-family: 'GTWalsheim';
  color: #fff;
  background-color: #A8DADC;
  font-size:1.25rem;
  border-radius:3rem;
  text-align:center;
  line-height:40px;
  opacity:1;
  height:40px;
  width:40px;
  margin-right:0.5rem;
  vertical-align:middle;
  @media (max-width: 960px) {
    margin-top:2rem;
  }
`

const Source = styled.a`
  font-style: italic;
  color: #1D3557;
  font-size:1rem;
  opacity:1 !important;
  font-weight:800;
`

const Recommandation = styled.div`
  background-color:#fff;
  padding:2rem;
`
const RecommandationText = styled.p`
  padding:0rem;
  margin:0rem;
  font-family:'GTWalsheim';
  font-size: 1rem;
  text-align: justify;
  text-justify: distribute;
  font-style: italic;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  opacity:0.5;
`
const RecommandationFooter = styled.div`
  display:flex;
  align-items:center;
  justify-content:space-between;
  margin-top:3vh;
`

const RecommandationProfil = styled.div`
  font-family:'GTWalsheim';
  text-align: right;
  opacity:0.8;
`

const IndexPage = () => (
  <Layout>
    <SEO title="Développeur Web Backend Symfony Php Freelance à Lyon" />
    <Banner/>
    <Container>
      <Title>Une solution optimale.</Title>
      <Subtitle>Je façonne une solution adéquate à vos besoins en m'adaptant à votre secteur d'activité. Je met un point d'honneur à vous délivrer un code qualitatif, respectant les standards et exigences modernes tout en vous conseillant sur les bonnes pratiques.</Subtitle>
      <Grid lines={2} gap={3}>
        <Card>
          <CardTitle>
            Développement spécifique
          </CardTitle>
          <CardSubtitle>
            Pour créer la solution qui n'existe pas ailleurs.
          </CardSubtitle>
          <CardText>
            Les problèmes et les embûches rencontrés durant mes 7 ans d'expériences m'ont permis d'acquérir l'usage d'un ensemble d'outils adéquats ainsi qu'une vision d'ensemble des bonnes pratiques à mettre en oeuvre au sein de votre projet.
            <br/><br/>Que les questions soient d'ordre du domaine de l'architecture logicielle, de l'organisation du code, des outils ou des services à préférer pour un probléme donné, je développe une solution unique pour votre plateforme.
          </CardText>
        </Card>
        <Card>
          <CardTitle>
            Site vitrine
          </CardTitle>
          <CardSubtitle>
            Pour avoir une présence en ligne.
          </CardSubtitle>
          <CardText>
            Plus de 80% des entreprises en faillite en 2013 n’étaient pas présentes sur Internet. <Source href="https://www.lefigaro.fr/societes/2014/02/19/20005-20140219ARTFIG00039-internet-facteur-cle-dans-la-perennite-d-une-entreprise.php" target="_blank" rel="noopener noreferrer">Source lefigaro.fr</Source>
            <br/><br/>
            Valorisez votre activité ainsi que vos produits en améliorant votre notoriété en étant visible sur le web. Je développe un site moderne respectant les standards actuels afin que vous puissiez exister sur le web.
          </CardText>
        </Card>
        <Card>
          <CardTitle>
            E-commerce
          </CardTitle>
          <CardSubtitle>
            Pour vendre vos produits en ligne.
          </CardSubtitle>
          <CardText>
            Je vous aide à démarrer et faire évoluer votre activité de vente en ligne. Aujourd'hui les solutions ne manquent pas pour lancer une boutique en ligne, je vous accompagne dans votre entreprise et développe votre boutique e-commerce dans le but d'augmenter votre chiffre d'affaire.
          </CardText>
        </Card>
        <Card>
          <CardTitle>
            CMS
          </CardTitle>
          <CardSubtitle>
            Pour communiquer avec votre audience.
          </CardSubtitle>
          <CardText>
            Vous souhaitez rédiger et éditer votre contenu de façon récurrente pour communiquer avec votre communauté sur le web. Le blog fait parti des indispensbles marketing à mettre en place pour étendre sa communauté, faire parler de son produit et optimiser son référencement naturel.
          </CardText>
        </Card>
      </Grid>
    </Container>

    <Container>
      <section>
        <Title>Une méthodologie claire.</Title>
        <Subtitle>6 étapes clefs pour le bon déroulement de notre collaboration.</Subtitle>
        <Grid lines={2} gap={3}>
          <Card>
            <StepNumber>1</StepNumber>
            <StepTitle>
              Récolte des besoins
            </StepTitle>
            <CardText>
              Nous convenons d'un rendez-vous afin que vous me présentiez votre problématique. C'est un moment idéal pour analyser et vous conseiller afin que la solution soit en accord avec les besoins du projet.
            </CardText>
          </Card>
          <Card>
            <StepNumber>2</StepNumber>
            <StepTitle>Cahier des charges</StepTitle>
            <CardText>
              Vous établissez ensuite un cahier des charges précisant les détails du projet. Si c'est quelque chose de nouveau ou de difficile pour vous, je peux vous accompagner sur cette démarche.
            </CardText>
          </Card>
          <Card>
            <StepNumber>3</StepNumber>
            <StepTitle>Devis</StepTitle>
            <CardText>
              J'établis ensuite un devis détaillé au juste prix à partir du cahier des charges. Si le devis est accepté par vos soins, nous passons à la phase de réalisation. Si la taille du projet est conséquente, un accompte de 30% est indiqué.
            </CardText>
          </Card>
          <Card>
            <StepNumber>4</StepNumber>
            <StepTitle>Réalisation</StepTitle>
            <CardText>
              Cette phase me permet de mettre à profit mes 7 ans d'expériences diverses et variés en startups et grands groupes technologiques à la bonne réalisation de votre projet.<br/><br/>Je réalise cette étape depuis mon bureau mais je peux également me déplacer dans une limite kilométrique acceptable si le besoin d'une présence physique au sein de votre équipe est requise pour l'éxècution du projet.
            </CardText>
          </Card>
          <Card>
            <StepNumber>5</StepNumber>
            <StepTitle>Livraison</StepTitle>
            <CardText>
              Je vous livre une réalisation préalablement testée par mes soins à l'aide de tests fonctionnels et unitaires garants d'une solution répondant aux spécifications du cahier des charges.
            </CardText>
          </Card>
          <Card>
            <StepNumber>6</StepNumber>
            <StepTitle>Facturation</StepTitle>
            <CardText>
              Aprés quelques jours vous laissant apprécier la qualité de la réalisation, je vous envoie une facture payable directement en ligne depuis une plateforme sécurisée.
            </CardText>
          </Card>
        </Grid>
      </section>
    </Container>

    <CTA/>

    <Container>
      <section>
        <Title>Quelques avis à mon sujet.</Title>
        <Subtitle>Les avis sont issus de Malt.</Subtitle>
        <Grid lines={2} gap={3}>
          <Recommandation>
              <RecommandationText>
                "Je peux d'ores et déjà annoncer que Guillaume fait partie de ces personnes avec qui on prend plaisir à travailler. Il ne se contente pas d'écrire du code, et possède une approche véritablement liée au Produit : en discutant avec les différentes parties prenantes, il va chercher à comprendre les besoins, confronter les différentes idées émises, et en extraire la meilleure solution possible. Sa technicité acquise durant ses années d'expérience ainsi que son sens du détail en fait un allié précieux pour tout nouveau projet. Cerise sur le gâteau : il est très bon communicant, ce qui lui permet de gagner en efficacité aussi bien en interne lors de suivi de projets, qu'en externe lors de réunions avec clients. Je recommande sans hésitation."
              </RecommandationText>
              <RecommandationFooter>
                <img 
                  src={require('../images/peoples/margaux-beugnet.jpg')}
                  alt="Margaux Beugnet"
                  css={css`
                    border-radius: 60px;
                  `}
                /> 
                <RecommandationProfil>
                  Margaux Beugnet
                  <br/>
                  Product Owner à <a href="https://www.lengow.com/" target="_blank" rel="noopener noreferrer" css={css`color:#1D3557;border-bottom:3px solid #A8DADC;`}>Lengow</a>
                </RecommandationProfil>
              </RecommandationFooter>
          </Recommandation>

          <Recommandation>
              <RecommandationText>
                "Nous sommes toujours arrivés à nos fins grâce à son esprit analytique et son écoute des besoins. Force de proposition, soucieux du détail et en constante veille technologique, il saura vous guider dans la bonne direction, et mener votre projet à son terme."
              </RecommandationText>
              <RecommandationFooter>
                <img 
                  src={require('../images/peoples/tanguy-cleard.jpg')}
                  alt="Tanguy Cleard"
                  css={css`
                    border-radius: 60px;
                  `}
                /> 
                <RecommandationProfil>
                  Tanguy Cleard
                  <br/>
                  Expert Android à <a href="https://www.mister-auto.com/" target="_blank" rel="noopener noreferrer" css={css`color:#1D3557;border-bottom:3px solid #A8DADC;`}>Mister Auto</a>
                </RecommandationProfil>
              </RecommandationFooter>
          </Recommandation>

          <Recommandation>
              <RecommandationText>
                "Je ne peux que recommander Guillaume qui est un développeur sérieux, compétent et à l'écoute. Durant 2 ans nous avons mené à bien de nombreux projets web au sein de Yurplan. Ce fut un réel plaisir que d'imaginer, designer et concevoir des applications à ses côtés. Cette période fut très formatrice pour moi et grâce à son savoir-faire et ses conseils j'ai pu monter en compétences sur de nombreux aspects, tant techniques que humains."
              </RecommandationText>
              <RecommandationFooter>
                <img 
                  src={require('../images/peoples/clement-jacquelin.jpg')}
                  alt="Clément Jacquelin"
                  css={css`
                    border-radius: 60px;
                  `}
                /> 
                <RecommandationProfil>
                  Clément Jacquelin
                  <br/>
                  Ui Designer à <a href="https://www.365talents.com/fr/" target="_blank" rel="noopener noreferrer" css={css`color:#1D3557;border-bottom:3px solid #A8DADC;`}>365Talents</a>
                </RecommandationProfil>
              </RecommandationFooter>
          </Recommandation>

          <Recommandation>
              <RecommandationText>
                "Guillaume est un excellent développeur avec qui j'ai eu l'occasion de travailler au sein de Yurplan. Son énergie et ses connaissances techniques sont au service du projet et de l'équipe. C'est un plaisir de travailler avec quelqu'un possédant une telle expertise technique et un tel savoir faire."
              </RecommandationText>
              <RecommandationFooter>
                <img 
                  src={require('../images/peoples/thomas-deneulin.jpg')}
                  alt="Thomas Deneulin"
                  css={css`
                    border-radius: 60px;
                  `}
                /> 
                <RecommandationProfil>
                  Thomas Deneulin
                  <br/>
                  CTO à <a href="https://imageseo.io/fr/" target="_blank" rel="noopener noreferrer" css={css`color:#1D3557;border-bottom:3px solid #A8DADC;`}>ImageSEO</a>
                </RecommandationProfil>
              </RecommandationFooter>
          </Recommandation>

          <Recommandation>
            <RecommandationText>
              "Guillaume s’est parfaitement intégré à l’équipe. Il nous a apporté son expertise dans le développement Symfony / PHP et sur les bonnes pratiques à mettre en place. Il a fait preuve de pédagogie et s’est toujours montré disponible pour apporter des précisions sur son travail. Il a également su être force de propositions dans les choix techniques ou dans les possibilités d’améliorations. Ce fut un plaisir de travailler avec lui, c'est une personne avec un savoir faire et un savoir être indéniable."
            </RecommandationText>
            <RecommandationFooter>
                
                <img 
                  src={require('../images/peoples/cedric-herlin.jpg')}
                  alt="Cédric Herlin"
                  css={css`
                    border-radius: 60px;
                  `}
                />
                <RecommandationProfil>
                  Cédric Herlin
                  <br/>
                  Chef de projet à <a href="https://www.123roulement.com/" target="_blank" rel="noopener noreferrer" css={css`color:#1D3557;border-bottom:3px solid #A8DADC;`}>123Roulement</a>
                </RecommandationProfil>
            </RecommandationFooter>
          </Recommandation>
          <Recommandation css={css`
            display:flex;
            flex-direction: column;
            justify-content: center;
          `}>
              <RecommandationText css={css`
                font-style:normal;
                text-align:center;
              `}>
                Retrouvez les avis complets sur mon profil Malt.
              </RecommandationText>
              <RecommandationFooter css={css`
                display:block;
                text-align:center;
                margin-top:4vh;
              `}>
                <a 
                  css={css`
                    color: #1D3557;
                    border: 2px solid #1D3557;
                    padding:8px;
                    font-family: 'GTWalsheim';
                    font-size:1rem;
                  `}
                  href="https://www.malt.fr/profile/guillaumemourier" 
                  target="_blank" 
                  rel="noopener noreferrer">
                    <img 
                    src={require('../images/malt_picto_color.svg')}
                    alt="Pictogramme Malt"
                    height="20px"
                    width="20px" 
                    css={css`
                        display: inline-block;
                        vertical-align:middle;
                        margin-right:4px;
                        margin-top:-2px;
                    `}
                    />
                    Mon profil Malt
                </a>
              </RecommandationFooter>
          </Recommandation>
        </Grid>
      </section>
    </Container>
  </Layout>
)

export default IndexPage
