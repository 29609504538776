import React from "react"
import { css } from "@emotion/core"
import Container from "./Container"

const Banner = () => (
    <Container>
        <div
            css={css`
                display:flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom:15vh;
                @media (max-width: 960px) {
                    display:block;
                    text-align:center;
                }
            `}
        >
            <div>
                <h1
                    css={css`
                        font-family: 'GTWalsheim';
                        font-size:4rem;
                        letter-spacing: -0.08em;
                        line-height:10rem;
                        color:#1D3557;
                        margin-top:4vh;

                        @media (max-width: 960px) {
                           line-height:4rem;
                           font-size:3rem;
                        }
                    `}
                >
                    Guillaume Mourier
                </h1>
                <h2
                    css={css`
                        font-family: 'FreightTextBook';
                        font-size:2rem;
                        line-height:2.5rem;
                        opacity:0.8;
                        font-weight: 600;
                        max-width:45rem;
                    `}
                >
                    Je vous apporte mes conseils et mon expertise au service de la conception de vos produits numériques.
                </h2>
                <h3
                    css={css`
                        margin-top: 2.5vh;
                        font-family: 'FreightTextBook';
                        font-size:1.25rem;
                        opacity:0.6;
                        max-width:40rem;
                    `}
                >
                    Développeur web Backend Symfony en freelance, 7 ans d'expériences, enthousiaste opensource, basé à Lyon et sa région.
                </h3>
            </div>
            <div css={css`
                background-image:url(${require('../images/illustration_freelance.svg')});
                width:750px;
                height:300px;
                margin-top:8vh;
                background-size:cover;
                @media (max-width: 960px) {
                    display:none;
                }
            `}
            >
            </div>
        </div>
    </Container>
)

export default Banner

